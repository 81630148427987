<template>
  <div>
    <v-app id="inspire">
      <v-container v-if="!container">
        <!-- <div style="background-color:red;width:500px;height:500px;"></div> -->
        <div style="padding-left: 50%; padding-top: 10%">
          <div class="spinner spinner-primary spinner-lg mr-15"></div>
        </div>
        <div style="margin-top: 15px; text-align: center; margin-left: 4%">
          Lütfen bekleyiniz...
        </div>
      </v-container>
      <v-container v-if="container" >
        <v-dialog v-model="dialog"
        max-width="700px"
        style="height: 100%"
        persistent
        scrollable>
            <v-card class="elevation-5">
              <v-card-title>
                <v-row>
                  <v-col md="12"> Hasta Çalışma Tamamlama </v-col>
                </v-row>
              </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12">
                      <v-card-title>Çalışma Sonu</v-card-title>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="6">
                      <v-card-title>
                        Hasta çalışmayı tamamladı mı ?
                      </v-card-title>
                    </v-col>
                    <v-col md="6" style="margin: -15px">
                      <v-radio-group :mandatory="false">
                        <v-row>
                          <v-col md="3">
                            <input type="radio" label="Evet" value="Tamamlandı"
                              v-model="ReasonForIncompletion.statusUpdate" @change="getRadious()" />
                            <span>Evet</span>
                          </v-col>
                          <v-col md="3">
                            <input type="radio" label="Hayır" value="Tamamlanmadı"
                              v-model="ReasonForIncompletion.statusUpdate" @change="getRadious()" />
                            <span>Hayır</span>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row v-if="radiosNo2">
                    <v-col md="6">
                      <v-card-title>Hastanın Çalışmayı Tamamlama Tarihi:</v-card-title>
                    </v-col>
                    <v-col md="6 " style="margin: -15px">
                      <!-- <h6>{{this.lastVisitDate}}</h6> -->
                      <!-- <v-text-field
                        v-model="this.lastVisitDate"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        disabled
                      ></v-text-field> -->

                      <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :return-value.sync="date3"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :value="getDateFormat(date3)" append-icon="mdi-calendar" v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date3" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false" class="pr-10">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menu3.save(date3)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="
                    patientInfoListAddResult.productAppliedLastDate != null
                  ">
                    <v-col md="6">
                      <v-card-title style="word-break: break-word">
                        Hastaya Araştırma Ürününün Son Kez Uygulandığı Tarih:
                      </v-card-title>
                    </v-col>
                    <v-col md="6" style="margin-left: -15px; margin-right: -15px">
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :value="getDateFormat(date2)" append-icon="mdi-calendar" v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date2" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false" class="pr-10">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menu2.save(date)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="12">
                      <v-card-title>
                        <v-radio-group :mandatory="false" v-if="radiosNo">
                          <v-row>
                            <v-col md="1">
                              <input type="radio" label="Advers Etkiler" value="Advers Etkiler" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Advers Etkiler</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Anormal Test Bulgusu" value="Anormal Test Bulgusu" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Anormal Test Bulgusu</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Tedavi Başarısızlığı" value="Tedavi Başarısızlığı" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Tedavi Başarısızlığı</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Hastanın Tıbbi Durumunun artık ilaç gerektirmemesi"
                                value="Hastanın Tıbbi Durumunun artık ilaç gerektirmemesi" v-model="
                                  ReasonForIncompletion.statusUpdateNoRadio
                                " />
                            </v-col>
                            <v-col>
                              <span>Hastanın Tıbbi Durumunun artık ilaç
                                gerektirmemesi</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Protokol İhlali" value="Protokol İhlali" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Protokol İhlali</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Hastanın gönüllü olurunu geri çekmesi"
                                value="Hastanın gönüllü olurunu geri çekmesi" v-model="
                                  ReasonForIncompletion.statusUpdateNoRadio
                                " />
                            </v-col>
                            <v-col>
                              <span>Hastanın gönüllü olurunu geri çekmesi</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Belirlenen Vizitlere Gelmeme"
                                value="Belirlenen Vizitlere Gelmeme" v-model="
                                  ReasonForIncompletion.statusUpdateNoRadio
                                " />
                            </v-col>
                            <v-col>
                              <span>Belirlenen Vizitlere Gelmeme</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Bürokratik Sorunlar" value="Bürokratik Sorunlar" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Bürokratik Sorunlar</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="1">
                              <input type="radio" label="Ölüm" value="Ölüm" v-model="
                                ReasonForIncompletion.statusUpdateNoRadio
                              " />
                            </v-col>
                            <v-col>
                              <span>Ölüm</span>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-card-title>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-card-title>Yorumlar</v-card-title>
                      <v-card-text>
                        <v-textarea solo name="input-7-4" v-model="ReasonForIncompletion.comment"></v-textarea>
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-card-title>
                        <v-checkbox style="
                            margin: 0px !important ;
                            word-break: break-word;
                          " v-model="ReasonForIncompletion.signature"
                          label="Çalışma kapsamında ilgili hasta için veri girişini tamamladığımı, verileri tümüyle ve titizlikle gözden geçirdiğimi ve doğruluğunu onaylıyorum.">
                        </v-checkbox>
                        <!-- <v-row style="margin: auto">
                  <v-col style="font-weight:bold;font-size:12px"> -->
                        <span style="font-size: 12px !important" class="ml-3">
                          *Onayla butonuna tıklamanız ardından tüm vizitler
                          imzalanıcaktır.
                        </span>
                        <!-- </v-col>
                </v-row> -->
                      </v-card-title>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                <v-row style="margin: auto">
                  <v-col md="2 ml-auto ">
                    <button type="button" class="btn btn-secondary" @click="dialog = false">
                      Kapat
                    </button>
                  </v-col>
                  <v-col md="2">
                    <button type="button" class="btn btn-secondary" @click="confirmWorkSignature()" v-if="
                      ReasonForIncompletion.signature &&
                      (this.radiosNo || this.radiosNo2)
                    ">
                      Onayla
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- dialog hasta özeti calısma tamamlama end -->
        <!-- v-dialog bitti -->

        <!-- dialog imza start -->
        <v-dialog v-model="dialog2" max-width="700px" height="500px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">
                  Hasta Özeti: Vizit İmzalama
                </v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="dialog2 = false">mdi-close</v-icon>
              </v-col>
            </v-row>
            <!-- <v-card-title> Hasta Özeti: Vizit İmzalama </v-card-title> -->
            <div class="page-dialog">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="patientInfoList.patientVisits.signature" :label="
                      signatureStatus == 'Planlanan' ||
                      signatureStatus == 'Gerçekleşmedi'
                        ? ' Çalışmaya dahil edilen gönüllü ile ilgili vizitin gerçekleştirilmediğini teyit ederim.'
                        : 'Hastanın ilgili vizitine ait veri girişini tamamladığımı, verileri gözden geçirdiğimi ve doğruluğunu onaylıyorum.'
                    "></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <!-- <v-col md="2 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light-active"
                      @click="dialog2 = false"
                    >
                      Kapat
                    </button>
                  </v-col> -->
                  <v-col md="2 ml-auto">
                    <button type="button" class="btn btn-success" @click="SignIt"
                      :disabled="!patientInfoList.patientVisits.signature">
                      İmzala
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <!-- dialog imza end -->

        <v-dialog v-model="dialogConfirmSignature" max-width="500px" height="500px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">
                  Giriş</v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="
                  dialogConfirmSignature = false;
                  rules.name = [];
                ">mdi-close</v-icon>
              </v-col>
            </v-row>
            <!-- <v-card-title> Giriş</v-card-title> -->
            <div class="page-dialog">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field required v-model="signatureUserName" label="Kullanıcı Adı (*)" filled
                      :rules="rules.name" ref="signatureUserName"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field required v-model="signaturePassword" label="Şifre (*)" filled :rules="rules.password"
                      type="password" ref="signaturePassword"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <!-- <v-col md="4 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light-active"
                      @click="dialogConfirmSignature = false"
                    >
                      Kapat
                    </button>
                  </v-col> -->
                  <v-col md="4 ml-auto">
                    <button type="button" class="btn btn-success" @click="OpenDialog2" :disabled="
                      signatureUserName == '' || signaturePassword == ''
                    ">
                      İmzala
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <!-- Calısmayı bitir login dialog -->
        <v-dialog v-model="dialogComplateWorkLogin" max-width="500px" height="500px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">
                  Giriş</v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="
                  dialogComplateWorkLogin = false;
                  rules.name = [];
                ">mdi-close</v-icon>
              </v-col>
            </v-row>
            <!-- <v-card-title> Giriş</v-card-title> -->
            <div class="page-dialog">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field required v-model="complateWorkLoginUserName" label="Kullanıcı Adı (*)" filled
                      :rules="rules.name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field required v-model="complateWorkLoginPassword" label="Şifre (*)" filled
                      :rules="rules.password" type="password"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <!-- <v-col md="4 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light"
                      @click="dialogComplateWorkLogin = false"
                    >
                      Kapat
                    </button>
                  </v-col> -->
                  <v-col md="4 ml-auto">
                    <button type="button" class="btn btn-success" @click="sendCompletion()" :disabled="
                      complateWorkLoginUserName == '' ||
                      complateWorkLoginPassword == ''
                    ">
                      Çalışmayı Bitir
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <!-- Calismayi bitir login dialog -->

        <v-dialog v-model="dialog3" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
          <v-card>
            <v-toolbar flat dark color="rgb(68, 41, 65)" max-height="64px">
              <v-btn icon dark @click="turnBack">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ visitName }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <!-- <iframe
                :src="iframeLink"
                title="W3Schools Free Online Web Tutorials"
                style="height: 100%; width: 100%;margin-top:15px;"
              >
              </iframe> -->
              <iframe :src="iframeLink" title="W3Schools Free Online Web Tutorials"
                style="height: 100%; width: 100%; margin-top: 15px">
              </iframe>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Dialog  Vizit Atla -->
        <v-dialog v-model="dialogVisit" max-width="700px" height="500px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">
                  Vizit Atlama Sebebi</v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="dialogVisit = false">mdi-close</v-icon>
              </v-col>
            </v-row>
            <!-- <v-card-title>Vizit Atla</v-card-title> -->
            <div class="page-dialog">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card-title>Nedenler</v-card-title>
                    <v-radio-group v-model="selectedCause">
                      <v-radio label="Hastaya ulaşılamıyor" value="Hastaya ulaşılamıyor"></v-radio>
                      <v-radio label="Hastanın ikamet adresi değişti." value="Hastanın ikamet adresi değişti.">
                      </v-radio>
                      <!-- <v-radio
                        label="Hasta vefat etti"
                        value="Hasta vefat etti"
                      ></v-radio> -->
                      <v-radio label="Hasta gönüllü olurunu geri çekerek çalışmadan ayrıldığını bildirdi"
                        value="Hasta gönüllü olurunu geri çekerek çalışmadan ayrıldığını bildirdi"></v-radio>
                      <v-radio label="Araştırmacı dolayısıyla vizit düzenlenemedi (rapor, izin vb.)"
                        value="Araştırmacı dolayısıyla vizit düzenlenemedi (rapor, izin vb.)"></v-radio>
                      <v-radio label="Salgın hastalık" value="Salgın hastalık"></v-radio>
                      <v-radio label="Diğer" value="Diğer"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="selectedCause == 'Diğer'">
                  <v-col>
                    <v-textarea v-model="causeText" solo name="input-7-4" label="Diğer"></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <!-- <v-col md="2 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light-active"
                      @click="dialogVisit = false"
                    >
                      Kapat
                    </button>
                  </v-col> -->
                  <v-col md="2 ml-auto">
                    <button type="button" class="btn btn-success" :disabled="selectedCause == ''"
                      @click="saveSkipVisit()">
                      Kaydet
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <!-- Dialog Vizit Atla son -->

        <!-- Vizit Question Show -->

        <!-- <v-dialog
          v-model="dialogVisitQuestion"
          max-width="1000px"
          height="1200px"
          persistent
          scrollable
        >
          <v-card class="elevation-5">
            <v-row  style="align-items:center" class="px-5">
              <v-col cols="11">
            <v-card-title style="font-size:25px !important" > {{questionHeader}}</v-card-title>
            </v-col>
            <v-col cols="1" style="text-align:end" >
              
                 <v-icon color="white" @click="dialogVisitQuestion=false">mdi-close</v-icon>
            </v-col>
            </v-row>
            <div class="page-dialog" style="padding: 20px">
              <v-card-text v-for="(item, index) in visitQuestion" :key="index">
                <v-row style="font-size: 20px;padding-left:20px">
               {{ item.question }} 
      
                </v-row>
                <v-row style="font-size: 16px;padding-left:20px !important;padding-top:25px !important">
                    {{ item.answer }}
                </v-row>
                <v-divider style="margin-top:25px !important"></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col md="2 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light"
                      @click="dialogVisitQuestion = false"
                    >
                      Kapat
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog> -->

        <!-- Sorular pop up yeni -->
        <v-dialog v-model="dialogVisitQuestion" scrollable max-width="1000px" height="1200px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important;" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">{{ questionHeader }}
                </v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="dialogVisitQuestion = false">mdi-close</v-icon>
              </v-col>
            </v-row>

            <v-card-text style="margin: 1px !important">
              <div class="page-dialog" style="padding: 10px" v-for="(item, index) in visitQuestion" :key="index">
                <v-row style="
                    font-size: 18px !important;
                    padding-left: 20px;
                    padding-top: 15px !important;
                    font-weight: bold;
                  ">
                  {{ item.question }}
                </v-row>
                <v-row style="
                    font-size: 16px !important;
                    padding-left: 20px !important;
                    padding-top: 8px !important;
                  ">
                  {{ item.answer }}
                </v-row>

                <div v-if="item.subQuestions != null">
                  <div v-for="(item2, index2) in item.subQuestions" :key="index2">
                    <v-row style="
                        font-size: 16px !important;
                        padding-left: 35px;
                        padding-top: 10px !important;
                        font-weight: bold;
                      ">
                      {{ item2.question }}
                    </v-row>
                    <v-row style="
                        font-size: 14px !important;
                        padding-left: 35px !important;
                        padding-bottom: 10px !important;
                      ">
                      {{ item2.answer }}
                    </v-row>
                  </div>
                </div>
                <v-divider style="margin-top: 25px !important; margin-bottom: -10px !important"></v-divider>
              </div>

              <v-row style="background-color: white; color: black; font-size: 16px" class="px-6"
                v-if="questionPopupSignature.length > 0">
                <div v-for="(item2, index2) in questionPopupSignature" :key="index2">
                  <v-col>
                    İmza Tarihi : {{ getDateFormatForPopup(item2.createDate) }}
                  </v-col>
                  <v-col>
                    İmzalayan :
                    {{
                    item2.user != null
                    ? item2.user.name + " " + item2.user.surname
                    : ""
                    }}
                  </v-col>
                </div>
              </v-row>

              <v-row style=" margin-top: 5px;">
                <v-col md="2 ml-auto">
                  <button style="color:#442842 !important" type="button" class="btn btn-light" @click="dialogVisitQuestion = false">
                    Kapat
                  </button>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- <v-card-actions style="background-color:white !important">
             
              </v-card-actions> -->
          </v-card>
        </v-dialog>
        <!-- Vizit Question Show  End-->

        <!-- Değişiklik pop up start -->
        <v-dialog v-model="dialogUpdateVisitStatus" max-width="750px" height="750px" persistent>
          <v-card class="elevation-5">
            <v-row style="align-items: center; max-height: 90px !important" class="px-5">
              <v-col cols="11">
                <v-card-title style="font-size: 25px !important">
                  Veri Değişikliği</v-card-title>
              </v-col>
              <v-col cols="1" style="text-align: end">
                <v-icon color="white" @click="
                  dialogUpdateVisitStatus = false;
                  rules.updateVisitStatus = [];
                ">mdi-close</v-icon>
              </v-col>
            </v-row>
            <!-- <v-card-title> Giriş</v-card-title> -->
            <div class="page-dialog">
              <v-card-text>
                <v-row>
                  <v-col>

                    <span style="font-size: 12px !important">
                      *Değişiklik yaparak veri girişine devam etmek istiyorsanız aşağıdaki alana
                      değişiklik sebebini girerek sürece devam edebilirsiniz.
                    </span>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea required v-model="changedVisitStatus" label="Değişiklik Sebebi" filled
                      :rules="rules.updateVisitStatus" ref="changedVisitStatus"></v-textarea>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-actions>
                <v-row>
                  <!-- <v-col md="4 ml-auto">
                    <button
                      type="button"
                      class="btn btn-light-active"
                      @click="dialogConfirmSignature = false"
                    >
                      Kapat
                    </button>
                  </v-col> -->
                  <v-col md="4 ml-auto">
                    <button type="button" class="btn btn-success" @click="saveVisitStatus" :disabled="
                    !counVisitStatusText">
                      Onayla
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <!-- Değişiklik pop up end -->

        <v-container v-if="container">
          <v-row>
            <v-col>
              <v-card class="elevation-5">
                <v-card-title>{{ workInfoList.workShortName }}</v-card-title>

                <v-row>
                  <v-col md="4">
                    <v-card-text>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            Hasta No:
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{ patientNumber }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            Hasta Baş Harfi:
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{ patientInfoList.name }}
                            {{ patientInfoList.surname }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            BGOF İmza Tarihi:
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{ getDateFormat(patientInfoList.signatureDate) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col md="4 " class="ml-auto">
                    <v-card-text>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            Merkez Adı:
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{
                            patientInfoList.researcher.center == null
                            ? " "
                            : patientInfoList.researcher.center.centerName
                            }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            Bölüm:
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{ patientInfoList.researcher.specialtyDepartment }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col>
                          <span style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-bottom: 3px;
                            ">
                            Sorumlu Araştırmacı :
                          </span>
                          <span style="font-size: 14px; margin-bottom: 3px">
                            {{
                            patientInfoList.researcher.name +
                            " " +
                            patientInfoList.researcher.surname
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="12" md="12" sm="12" xs="12">
              <div class="page-card">
                <v-row>
                  <v-col>
                    <v-data-table :headers="headers2" :items="desserts" :footer-props="{
                      'items-per-page-options': [20, 30, 40, -1],
                    }" :items-per-page="20">
                      <template v-slot:[`item.date`]="{ item }">
                        <div>
                          {{
                          item.visitDate != null
                          ? getDateFormat(item.visitDate)
                          : ""
                          }}
                        </div>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <div v-if="
                          item.status != 'Disqualified' &&
                          item.status != 'Çalışma dışı'
                        " class="progressbar">
                          <div class="progressbar text-center" style="
                              background-color: #63c384;
                              margin: 0;
                              color: white;
                            " :style="{
                              backgroundColor:
                                item.status == 'Gerçekleşmedi'
                                  ? 'gray'
                                  : item.status == 'Tamamlandı'
                                  ? 'green'
                                  : item.status == 'Tamamlanmadı'
                                  ? '#ff4e4e'
                                  : item.status == 'Devam ediyor'
                                  ? 'yellow'
                                  : 'white',
                              color:
                                calculateDay(item.visitDate) <=
                                item.visit.work.visitInterval / 25
                                  ? 'black'
                                  : 'black',
                            }">
                            {{ item.status }}
                          </div>
                        </div>
                        <div v-if="
                          item.status == 'Disqualified' ||
                          item.status == 'Çalışma dışı'
                        " class="progressbar2">
                          <div class="progressbar2 text-center" style="
                              background-color: #ff0000;
                              margin: 0;
                              color: white;"
                               :style="{ width: item.status }">
                            {{ item.status }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:[`item.signature`]="{ item }">
                        <v-row v-if="item.active">
                          <v-col>
                            <v-icon class="fas fa-check-square fa-custom-signature" v-if="item.signature == true">
                            </v-icon>
                            <v-icon class="far fa-square fa-custom-signature" v-if="
                              item.signature == null ||
                              item.signature == false
                            " @click="getSignIt(item)"></v-icon>
                            <!-- <v-icon
                          class="far fa-square fa-custom-signature"
                          v-if="
                            (item.signature == null ||
                              item.signature == false) &&
                            item.status != 'Tamamlandı'
                          "
                        ></v-icon> -->
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`item.lock`]="{ item }">
                        <v-row v-if="item.active">
                          <v-col>
                            <v-icon class="fas fa-lock fa-locked" v-if="item.lock" @click="getUnlockIt(item)"></v-icon>
                            <v-icon class="fas fa-lock-open fa-locked" v-if="!item.lock" @click="getLockIt(item)">
                            </v-icon>
                          </v-col>
                        </v-row>
                      </template>
                      <!-- <template v-slot:[`item.incele`]="{ item, index }">
                        <v-row v-if="item.active">
                          <v-col>
                            <v-icon
                              class="fas fa-chevron-circle-right text-warning"
                              @click="showİframe(item)"
                              v-if="
                                ((item == desserts[0] &&
                                  (item.status != 'Tamamlandı' ||
                                    item.status == 'Tamamlandı')) ||
                                  desserts[index - 1].status == 'Tamamlandı') &&
                                item.lockCheck == false
                              "
                            >
                            </v-icon>
                            <v-icon
                              class="fas fa-chevron-circle-right"
                              v-if="
                                (index - 1 != -1 &&
                                  desserts[index - 1].status != 'Tamamlandı') ||
                                item.lockCheck == true
                              "
                            >
                            </v-icon>
                          </v-col>
                        </v-row>
                      </template> -->
                      <template v-slot:[`item.incele`]="{ item, index }">
                        <v-row v-if="item.active" style="align-items: center">
                          <v-col cols="2">
                            <button @click="test(item)">
                              <!-- <v-icon
                                class="fas fa-chevron-circle-right text-warning"
                                v-if="
                                  ((item == desserts[0] &&
                                    (item.status != 'Tamamlandı' ||
                                      item.status == 'Tamamlandı')) ||
                                    desserts[index - 1].status ==
                                      'Tamamlandı') &&
                                  item.lockCheck == false
                                "
                              >
                              </v-icon> -->
                              <v-icon class="fas fa-chevron-circle-right text-warning" v-if="item.active || item.status =='Çalisma disi'">
                              </v-icon>
                            </button>
                            <!-- <v-icon
                              class="fas fa-chevron-circle-right"
                              v-if="
                                (index - 1 != -1 &&
                                  desserts[index - 1].status != 'Tamamlandı') ||
                                item.lockCheck == true
                              "
                            >
                            </v-icon> -->
                            <v-icon class="fas fa-chevron-circle-right" v-if="!item.active &&item.status!='Çalisma disi'">
                            </v-icon>
                          </v-col>

                          <template>
                            <v-col cols="10" v-if="fab && item.editable">
                              <v-row style="margin: 10px">
                                <v-col>
                                  <v-btn x-small color="info" @click="showİframe(item)" style="
                                      width: 100%;
                                      margin-bottom: 5px !important;
                                    " v-if="item.status!='Tamamlanmadı' && item.signature==false &&item.status!='Çalışma dışı'&&item.status!='Amiloid Yok' && !item.status.toLowerCase().includes('ger') && patientInfoList.signature!=true">
                                    {{item.status=='Tamamlandı'?'Düzenle':'Devam Et'}}
                                  </v-btn>

                                  <v-btn v-if="
                                  (!(item.status.toLowerCase().includes('başlamadı')||item.status.toLowerCase().includes('planlanan')))" x-small color="info"
                                    @click="openShowPopup(item)" style="
                                      width: 100%;
                                      margin-bottom: 5px !important;
                                    ">
                                    Görüntüle
                                  </v-btn>

                                  <v-btn v-if="
                                  index != 0 &&
                                  ((item.status.toLowerCase().includes('planlanan')|| item.status.toLowerCase().includes('devam ediyor'))) &&desserts.indexOf(item)!= desserts.length-1" x-small color="info" @click="openVisitDialog(item)" style="width: 100%;margin-bottom: 5px !important;
                                    ">
                                    Viziti Atla
                                  </v-btn>

                                </v-col>
                              </v-row>
                            </v-col>
                          </template>
                        </v-row>
                        <!-- <v-row v-if="item.active">
                          <v-speed-dial
                            :id="index"
                            v-model="fab"
                            transition="scale-transition"
                            direction="right"
                          >
                            <v-row slot="activator" fab flat small>
                              <v-col>
                               <button @click="test(item,index)">
                                <v-icon
                                  class="
                                    fas
                                    fa-chevron-circle-right
                                    text-warning
                                  "
                                  v-if="
                                    ((item == desserts[0] &&
                                      (item.status != 'Tamamlandı' ||
                                        item.status == 'Tamamlandı')) ||
                                      desserts[index - 1].status ==
                                        'Tamamlandı') &&
                                    item.lockCheck == false
                                  "
                                  
                                >
                                </v-icon>
                                </button>

                                <v-icon
                                  class="fas fa-chevron-circle-right"
                                  v-if="
                                    (index - 1 != -1 &&
                                      desserts[index - 1].status !=
                                        'Tamamlandı') ||
                                    item.lockCheck == true
                                  "
                                >
                                </v-icon>
                              </v-col>
                            </v-row>
                            <v-row >
                              <v-col>
                                <v-btn
                                  x-small
                                  color="info"
                                  @click="showİframe(item)"
                                  style="width: 80%"
                                >
                                  Devam Et
                                </v-btn>
                                <v-btn
                                  x-small
                                  color="info"
                                  @click="openShowPopup(item)"
                                  style="width: 80%"
                                >
                                  Görüntüle
                                </v-btn>
                              
                                <v-btn
                                  v-if="index != 0"
                                  x-small
                                  color="info"
                                  @click="openVisitDialog(item)"
                                  style="width: 80%"
                                >
                                  Viziti Atla
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-speed-dial>
                        </v-row> -->
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="8 ml-auto">
                    <v-row>
                      <v-col md="4 ml-auto" lg="5 ml-auto">
                        <router-link to="/worksdetails/?tab=hastalar">
                          <button type="button" @click="turnWorkDetail()" class="btn btn-primary">
                            Hasta Listesine Dön
                          </button>
                        </router-link>
                      </v-col>
                      <v-col md="3">
                        <button type="button" @click="completeWork()" class="btn btn-primary"
                          v-if="patientInfoList.signature != true">
                          Çalışmayı Bitir
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  margin: 0px;
}

.progressbar {
  width: 100%;
  height: 20px;
  background-color: #eee;
  margin: 1em auto;
  transition: width 500ms;
}

.progressbar2 {
  width: 100%;
  height: 20px;
  background-color: #eee;
  margin: 1em auto;
  transition: width 500ms;
  color: white;
}

@import "@/global.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import jwtService from "../../core/services/jwt.service";
export default {
  name: "patientdetails",
  data() {
    return {
      updateVisitId: "",
      counVisitStatusText: false,
      changedVisitStatus: "",
      signatureStatus: "",
      questionHeader: "",
      visitQuestion: [],
      questionPopupSignature: [],
      fab: false,
      skipVisitItem: {},
      selectedCause: "",
      causeText: "",
      signatureUserName: "",
      signaturePassword: "",
      complateWorkLoginUserName: "",
      complateWorkLoginPassword: "",
      rules: {
        name: [(val) => !!val || "Bu alan doldurulmalı"],
        updateVisitStatus:
          [val => (val).length > 10 || "Minimum 10 karakter"]
      },
      lastVisitDate: "",
      statusUpdate: "",
      statusUpdateNoRadio: "",
      radiosYes: false,
      radiosNo: false,
      radiosNo2: false,
      iframeShow: false,
      iframeLink: "",
      container: true,
      imzaDurum: "",
      workID: "",
      patientID: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialogConfirmSignature: false,
      dialogComplateWorkLogin: false,
      dialogVisit: false,
      dialogVisitQuestion: false,
      dialogUpdateVisitStatus: false,
      date2: new Date().toISOString().substr(0, 10),
      date3: new Date().toISOString().substr(0, 10),
      desserts: [],
      headers2: [
        {
          text: "Vizit Adı",
          align: "left",
          value: "visitName",
        },
        { text: "Vizit Tarihi", value: "date" },
        { text: "Durum", value: "status", align: "center" },
        { text: "Advers Olay", value: "-" },
        { text: "İmza", value: "signature", sortable: false },
        { text: "Kilitle/Aç", value: "lock", sortable: false },
        { text: "Vizite Git", value: "incele", sortable: false, width: "20%" },
      ],
      workInfoList: [],
      patientInfoList: [],
      patientVisitid: "",
      ReasonForIncompletion: {
        id: null,
        statusUpdate: "",
        statusUpdateNoRadio: "",
        comment: "",
        signature: false,
      },
      visitName: "",
      patientNumber: "",
      //lockCheck:"",
      patientInfoListAddResult: {},
      fabTitle: "",
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    msg: {
      type: String,
      default: "Lütfen Bekleyiniz...",
    },
    width: {
      type: Number,
      default: 300,
    },
    color: {
      type: String,
      default: "grey",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    barColor: {
      type: String,
      default: "white",
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    percentComplete: {
      type: Number,
      default: 100,
    },
  },
  watch: {
    changedVisitStatus: function (e) {
      console.log("this. rule count", e.length);
      if (e.length > 10) {
        this.counVisitStatusText = true
      }
      else {
        this.counVisitStatusText = false
      }
    }

  },
  mounted() {
    //console.log("this.radis", this.radiosNo);
    // let token = jwtService.getToken();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Hasta Detayları", route: "/patientdetails/:id" },
    ]);
    this.patientID = this.$route.params.id;
    this.ReasonForIncompletion.id = this.patientID;
    this.workID = window.localStorage.getItem("getWorkID");
    let url = this.$route.params.surveyId;
    console.log("url", url);
    if (url != undefined) {
      this.showİframeWithSurveyId(url);
      ApiService.get("patient", this.patientID)
        .then(({ data }) => {
          console.log("Hasta bilgileri geldi ", data);
          this.patientInfoList = data.result;
          this.desserts = data.result.patientVisits;
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      this.getPatientInfo();
      this.getUserWorkDetails();
    }
  },
  methods: {
    test(item) {
      this.desserts.forEach((x) => {
        x.editable = false;
      });
      if (item.visitName != this.fabTitle) {
        this.fab = false;
      }

      item.editable = !item.editable;
      this.fab = !this.fab;
      this.fabTitle = item.visitName;
    },
    turnWorkDetail() {
      window.localStorage.setItem("getWorkID", this.workID);
    },
    getPatientInfo() {
      this.container = false;
      ApiService.get("patient", this.patientID)
        .then(({ data }) => {
          console.log("Hasta bilgileri geldi ", data);
          this.patientInfoList = data.result;
          this.patientInfoListAddResult = data.addResult;
          this.desserts = data.result.patientVisits;
          this.lastVisitDate = this.getDateFormat(
            data.addResult.productAppliedLastDate
          );
          console.log("Hasta bilgileri geldi ", this.desserts);
          this.container = true;
          this.patientNumber = data.result.patientNo;
          this.desserts.forEach((element) => {
            element.lockCheck = data.result.lock;
          });

          this.desserts.forEach((x) => {
            x.editable = false;
          });
          //console.log("aa", this.desserts);
        })
        .catch(() => {
          //console.log("error geldi", err);
        });
    },
    getUserWorkDetails() {
      ApiService.get("work", this.workID)
        .then(({ data }) => {
          //console.log("Çalışmalar geldi", data.result);
          this.workInfoList = data.result;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    getSignIt(item) {
      this.signatureStatus = item.status;
      //console.log("item patient : ", this.workInfoList);
      this.dialog2 = true;
      this.patientVisitid = item.id;

      //console.log("idyi tuttuk", item.id);
    },

    OpenDialog2() {
      this.dialogConfirmSignature = true;
      //console.log("patienınfolist:", this.patientInfoList);

      let obj = {
        username: this.signatureUserName,
        password: this.signaturePassword,
        PatientId: this.patientID,
      };
      ApiService.post("loginforsignature", obj)
        .then((res) => {
          console.log("login olduk res : ", res);
          let stateData = jwtService.getUser();

          if (res.data.email != stateData.email) {
            return Swal.fire({
              title: "",
              text: "Kullanıcılar eşleşmiyor.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: " Başarılı",
              icon: "success",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });

            let body = {
              id: this.patientVisitid,
              signature: this.patientInfoList.patientVisits.signature,
            };
            console.log("body : ", body);

            ApiService.put("patientvisit", body)
              .then(() => {
                //console.log("İmza sonucu geldi", data);
                this.imzaDurum = "imzalandı";
                this.getPatientInfo();
                Swal.fire({
                  title: "",
                  text: " Vizit İmzalandı",
                  icon: "success",
                  confirmButtonClass: "btn btn-primary",
                  heightAuto: false,
                });
              })
              .catch((err) => {
                //console.log("error", err);
                Swal.fire({
                  title: "",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: false,
                });
              })
              .then(() => {
                this.dialogConfirmSignature = false;
              });
          }
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .then(() => {
          this.signatureUserName = "";
          this.signaturePassword = "";
          this.rules.name = [];
        });
    },
    SignIt() {
      console.log("work info check : ", this.workInfoList);
      if (this.workInfoList.isSignatureRequired) {
        this.dialog2 = false;
        this.dialogConfirmSignature = true;
      } else {
        this.dialog2 = true;
        let body = {
          id: this.patientVisitid,
          signature: this.patientInfoList.patientVisits.signature,
        };

        ApiService.put("patientvisit", body)
          .then(() => {
            //console.log("İmza sonucu geldi", data);
            this.imzaDurum = "imzalandı";
            this.getPatientInfo();
            Swal.fire({
              title: "",
              text: " Vizit İmzalandı",
              icon: "success",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
          })
          .catch((err) => {
            //console.log("error", err);
            Swal.fire({
              title: "",
              text: err.response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .then(() => {
            this.dialog2 = false;
          });
      }

      //console.log("checkbox contorl",this.patientInfoList.patientVisits.lock)
    },
    confirmWorkSignature() {
      if (this.workInfoList.isSignatureRequired) {
        this.dialogComplateWorkLogin = true;
        this.dialog = false;
      } else {
        let body = {
          id: this.patientID,
          status: this.ReasonForIncompletion.statusUpdate,
          ReasonForIncompletion:
            this.ReasonForIncompletion.statusUpdateNoRadio +
            " " +
            this.ReasonForIncompletion.comment +
            " " +
            this.date2,
          signature: this.ReasonForIncompletion.signature,
        };
        //console.log("servise gidecek", body);
        ApiService.post("updatePatient", body)
          .then(() => {
            //console.log("Data burada", data);
            Swal.fire({
              title: "",
              text: "Çalışma Tamamlandı",
              icon: "success",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
            this.$router.push({
              name: "worksdetails",
            });
          })
          .catch((err) => {
            //console.log("error", err);
            Swal.fire({
              title: "",
              text: err.response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .then(() => {
            this.dialog = false;
          });
      }
    },
    openVisitDialog(item) {
      //console.log("item test: ",item);
      this.skipVisitItem = item;
      this.dialogVisit = true;
    },
    saveSkipVisit() {
      let body = {
        patientVisitId: this.skipVisitItem.id,
        reason: this.causeText == "" ? this.selectedCause : this.causeText,
      };
      ApiService.post("reasonforskip", body)
        .then(() => {
          //console.log("cevap : ",data);
          Swal.fire({
            icon: "success",
            title: "Vizit Atlama İşlemi Başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getPatientInfo();
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .then(() => {
          this.dialogVisit = false;
        });
    },
    getLockIt(item) {
      // console.log("id", item.id);
      //console.log("lock", this.patientInfoList.patientVisits.lock);
      let body = { id: item.id, lock: true };
      //console.log("body", body);
      ApiService.put("patientvisit", body)
        .then(() => {
          //console.log("lock geldi", data);
          this.getPatientInfo();
          Swal.fire({
            title: "",
            text: "Vizit kilitlendi",
            icon: "success",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false,
          });
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getUnlockIt(item) {
      // console.log("id", item.id);
      //console.log("lock", this.patientInfoList.patientVisits.lock);
      let body = { id: item.id, lock: false };
      //console.log("body", body);
      ApiService.put("patientvisit", body)
        .then(() => {
          //console.log("unlock geldi", data);
          this.getPatientInfo();
          Swal.fire({
            title: "",
            text: "Vizit kilidi açıldı",
            icon: "success",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false,
          });
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getDateFormat(item) {
      let date = item ? item.split("T")[0] : "-";

      let date2 = new Date(date).toLocaleString("tr");

      let date3 = date2 ? date2.split(" ")[0] : "-";

      return date3;
    },

    getDateFormatForPopup(item) {
      let temp = new Date(item);

      let year = temp.getFullYear();
      let month = temp.getMonth();
      let day = temp.getDate();
      let hour = temp.getHours();
      let min = temp.getMinutes();
      let second = temp.getSeconds();

      let fullDate =
        day +
        "/" +
        month +
        "/" +
        year +
        " " +
        hour +
        ":" +
        min +
        ":" +
        second +
        (hour < 12 ? "am" : "pm");

      return fullDate;
    },

    calculateDayNow(item) {
      let dateBefore = new Date(item);
      var beforeweek = new Date(
        dateBefore.getFullYear(),
        dateBefore.getMonth() + 1,
        dateBefore.getDate()
      ).toLocaleString("tr");
      let abeforeweek = beforeweek.split(" ")[0];

      let beforeDD = abeforeweek.split(".")[0];
      let beforeMM = abeforeweek.split(".")[1];
      let beforeYYYY = abeforeweek.split(".")[2];

      let beforeDate = beforeMM + "/" + beforeDD + "/" + beforeYYYY;
      //console.log("date",beforeDate);

      let now = new Date().toLocaleString("tr");
      now = now.split(" ")[0];
      let nowDD = now.split(".")[0];
      let nowMM = now.split(".")[1];
      let nowYYYY = now.split(".")[2];
      let nowDate = nowMM + "/" + nowDD + "/" + nowYYYY;
      //console.log("şu an :",nowDate);
      if (beforeweek < now) {
        //console.log("kücük");
        return 0;
      } else {
        const diffTimeBefore = Math.abs(
          new Date(beforeDate) - new Date(nowDate)
        );
        const diffDaysBefore = Math.ceil(
          diffTimeBefore / (1000 * 60 * 60 * 24)
        );
        return diffDaysBefore;
      }
    },
    calculateDayBefore(item) {
      //1  hafta öncesi hesaplama
      let dateBefore = new Date(item);
      var beforeweek = new Date(
        dateBefore.getFullYear(),
        dateBefore.getMonth(),
        dateBefore.getDate() - 7
      ).toLocaleString("tr");
      let abeforeweek = beforeweek.split(" ")[0];

      let beforeDD = abeforeweek.split(".")[0];
      let beforeMM = abeforeweek.split(".")[1];
      let beforeYYYY = abeforeweek.split(".")[2];

      let beforeDate = beforeMM + "/" + beforeDD + "/" + beforeYYYY;

      //Şuanki tarih hesaplama
      let now = new Date().toLocaleString("tr");
      now = now.split(" ")[0];
      let nowDD = now.split(".")[0];
      let nowMM = now.split(".")[1];
      let nowYYYY = now.split(".")[2];
      let nowDate = nowMM + "/" + nowDD + "/" + nowYYYY;

      //Öncesi formül :

      const diffTimeBefore = Math.abs(new Date(nowDate) - new Date(beforeDate));
      const diffDaysBefore = Math.ceil(diffTimeBefore / (1000 * 60 * 60 * 24));
      return diffDaysBefore;
    },
    calculateDay(item) {
      //Vizit tarihi gün hesaplama
      let date = new Date(item).toLocaleString("tr");
      date = date.split(" ")[0];

      let dateDD = date.split(".")[0];
      let dateMM = date.split(".")[1];
      let dateYYYY = date.split(".")[2];
      let visitDate = dateMM + "/" + dateDD + "/" + dateYYYY;
      //console.log("before date :",visitDate);
      //Şuanki tarih hesaplama
      let now = new Date().toLocaleString("tr");
      now = now.split(" ")[0];
      let nowDD = now.split(".")[0];
      let nowMM = now.split(".")[1];
      let nowYYYY = now.split(".")[2];
      let nowDate = nowMM + "/" + nowDD + "/" + nowYYYY;
      //console.log("now date :",nowDate);
//showİframe
      const diffTime = new Date(visitDate) - new Date(nowDate);
      //console.log("diff time ",diffTime);

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //console.log("diff days ",diffDays);

      return diffDays;
    },
    showİframe(item) {
      //console.log("fonksiyon calisti");
      //this.container = false;
      //console.log("item", item);
      this.updateVisitId = item.id
      console.log("item nedir : ", item);
      if (item.status == 'Tamamlandı') {

        this.dialogUpdateVisitStatus = true;
       // this.visitName = item.visitName;
       // if (!item.surveyUrl.includes("https")) {
      //    this.iframeLink = "https://" + item.surveyUrl;
      //  } else {
      //    this.iframeLink = item.surveyUrl;
      //  }
     }else {
        this.visitName = item.visitName;
        this.dialog3 = true;
        this.iframeShow = true;
        
        var currentUserName= window.localStorage.getItem("CurrentUser");
        if (!item.surveyUrl.includes("https")) {
          this.iframeLink = "https://" + item.surveyUrl+"&noticer="+currentUserName;
          console.log("link",this.iframeLink)
        } else {
          this.iframeLink = item.surveyUrl+"&noticer="+currentUserName;
          console.log("link",this.iframeLink)
        }
     }

    },
    saveVisitStatus() {
      let body = {
        patientVisitId: this.updateVisitId,
        reason: this.changedVisitStatus,
        PatientResearcherId:this.patientInfoList.researcher.id
      };
      console.log("body geldi : ", this.patientInfoList);
      ApiService.post("changevisitstatus", body)
        .then(() => {
          //console.log("cevap : ",data);
          Swal.fire({
            icon: "success",
            title: "Veri Güncelemesi başarılı",
            showConfirmButton: false,
            timer: 1200,
          })
          
        }).then(() => {
          this.dialog3 = true;
          this.iframeShow = true;
          this.iframeLink=this.patientInfoList.patientVisits.find(i=>i.id==this.updateVisitId).surveyUrl;
          })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .then(() => {
          this.dialogUpdateVisitStatus = false;
          this.changedVisitStatus = ""
        });
    },
    showİframeWithSurveyId(item) {
      //console.log("fonksiyon calisti");
      //this.container = false;
      //this.visitName = item.visitName;

      //console.log("item", item);
      this.container = true;
      this.dialog3 = true;
      this.iframeShow = true;
      this.iframeLink = item;
    },
    turnBack() {
      this.dialog3 = false;
      this.container = true;
      this.iframeShow = false;
      this.fab = false;
      this.getUserWorkDetails();
      this.getPatientInfo();
    },
    completeWork() {
      //this.dialog = true;
      this.dialog = true;
      this.date2 =
        this.patientInfoListAddResult.productAppliedLastDate != null
          ? this.patientInfoListAddResult.productAppliedLastDate
          : this.date2;
    },
    sendCompletion() {
      let obj = {
        username: this.complateWorkLoginUserName,
        password: this.complateWorkLoginPassword,
        PatientId: this.patientID,
      };
      ApiService.post("loginforsignature", obj)
        .then(() => {
          let body = {
            id: this.patientID,
            status: this.ReasonForIncompletion.statusUpdate,
            ReasonForIncompletion:
              this.ReasonForIncompletion.statusUpdateNoRadio +
              " " +
              this.ReasonForIncompletion.comment +
              " " +
              this.date2,
            signature: this.ReasonForIncompletion.signature,
          };
          //console.log("servise gidecek", body);
          ApiService.post("updatePatient", body)
            .then(() => {
              //console.log("Data burada", data);
              Swal.fire({
                title: "",
                text: "Çalışma Tamamlandı",
                icon: "success",
                confirmButtonClass: "btn btn-primary",
                heightAuto: false,
              });
              this.$router.push({
                name: "worksdetails",
              });
            })
            .catch((err) => {
              //console.log("error", err);
              Swal.fire({
                title: "",
                text: err.response.data.message,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            })
            .then(() => {
              this.dialog = false;
            });
        })
        .catch((err) => {
          //console.log("errror", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
        });

      // yeni
      //this.dialog = false;
      //console.log("body gicedek", this.ReasonForIncompletion);
    },
    getRadious() {
      if (this.ReasonForIncompletion.statusUpdate == "Tamamlanmadı") {
        this.radiosNo = true;
        this.radiosNo2 = false;
      } else {
        this.radiosNo = false;

        this.radiosNo2 = true;
      }
    },
    openShowPopup(item) {
      console.log("itemssss", item);
      this.questionPopupSignature = item.signingInformations;
      this.questionHeader = item.visitName;
      (this.container = false),
        ApiService.get("viewvisit", item.id)
          .then(({ data }) => {
            this.visitQuestion = data.result;
            this.container = true;
          })
          .catch(() => {
            //console.log("error", err);
          })
          .then(() => {
            this.dialogVisitQuestion = true;
          });
    },
  },
};
</script>
